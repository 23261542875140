/* Apply styles for devices with max-width 1366px and max-height 768px */
@media screen and (min-width: 768px){

 .contents {
  max-width: 614px !important;
 }
}

body {
  color:#fff;
  font-family: "Noto Sans KR", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  background-color: #000;
}

html, body {
  max-width: 100%;
  overflow-x: hidden;
}

input {
  font-family: "Noto Sans KR", sans-serif;
  /* font-optical-sizing: auto; */
  font-weight: 400;
  
}

.font500 {
  font-weight: 500;
  font-family: "Noto Sans KR", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

.contents {
  text-align: center;
  background-color: #000;
  color: #fff;
  max-width:767px;
  /* border: #fff solid 1px; */
  margin: 0 auto;
}

.bg-video {
  max-width: 100%;
  /* margin-top: 8em; */
  margin-bottom: 1em;
}
/* 
input[type="submit"] {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
} */

input {
  border-radius: 0;
}

input[type="text"]
, input[type="tel"]
, input[type="email"]
 {
  background-color: transparent;
  border: none;
  -webkit-appearance: none;
  border-bottom: 1px solid #fff;
}

input[type=text]:focus
, input[type="tel"]:focus
, input[type="email"]:focus
, textarea:focus {
  outline: none !important;
  -webkit-appearance: none;
  border-bottom: 1px solid #fff;
}

input[type="submit"] {
  margin-top: 1em;
  border: 1px solid #fff; /* 테두리 */
  border-radius: 2.1em; /* 반원 형태의 양 끝점 */
  background-color: transparent;
  color: #fff; /* 텍스트 색상 */
  width: 100%;
  height: 4.2em;
  font-size: 1em
  /* height: 4em !important;
  border-radius: 2em !important;  */
}

video::-internal-media-controls-download-button {
  display:none !important;
}

video::-webkit-media-controls-enclosure {
  overflow:hidden !important;
}

video::-webkit-media-controls-panel {
  width: calc(100% + 30px) !important; /* Adjust as needed */
}
